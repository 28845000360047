













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TypeErrorElem extends Vue {
  @Prop(String) private img!: string;
  @Prop(String) private title!: string;
}
